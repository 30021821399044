.container-footer {
    background-color: #363636;
    color: #fff;
    padding-top: 3%;
    width: 100%;
}

.footer-features {
    background-color: #1C1C1C;
    padding: 1%;
    display: flex;
    justify-content: center;
}
